import Vue from "vue";
import Router from "vue-router";

// Routes
import AppsRoutes from "./apps.routes";
import UIRoutes from "./ui.routes";
import PagesRoutes from "./pages.routes";
import UsersRoutes from "./users.routes";
import GtfsRoutes from "./gtfs.routes"
import CustomersRoutes from "./customers.routes";
import ProjectsRoutes from "./projects.routes";
import EcommerceRoutes from "./ecommerce.routes";
import LandingRoutes from "./landing.routes";
import DatabasesRoutes from "./databases.routes";

Vue.use(Router);

export const routes = [
  {
    path: "/",
    redirect: "/dashboard/analytics"
  },
  {
    path: "/dashboard/analytics",
    name: "dashboard-analytics",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/pages/dashboard/TellaeDashboardPage.vue"
      )
  },
  {
    path: "/dashboard/analytics2",
    name: "dashboard-analytics2",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/pages/dashboard/DashboardPage.vue"
      )
  },
  ...AppsRoutes,
  ...UIRoutes,
  ...PagesRoutes,
  ...UsersRoutes,
  ...GtfsRoutes,
  ...EcommerceRoutes,
  ...DatabasesRoutes,
  ...LandingRoutes,
  ...ProjectsRoutes,
  ...CustomersRoutes,
  {
    path: "/blank",
    name: "blank",
    component: () =>
      import(/* webpackChunkName: "blank" */ "@/pages/BlankPage.vue")
  },
  {
    path: "*",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/pages/error/NotFoundPage.vue"),
    meta: {
      layout: "error"
    }
  }
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL || "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes
});

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next();
});

/**
 * After each route update
 */
router.afterEach((to, from) => {});

export default router;
