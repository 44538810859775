import Vuetify from "../../plugins/vuetify";

export default {
  /**
   * Main Toast
   */
  showToast: (state, toast) => {
    const { color, timeout, message } = toast;

    state.toast = {
      message,
      color,
      timeout,
      show: true
    };
  },
  hideToast: state => {
    state.toast.show = false;
  },
  resetToast: state => {
    state.toast = {
      show: false,
      color: "black",
      message: "",
      timeout: 3000
    };
  },

  /**
   * Theme and Layout
   */
  setGlobalTheme: (state, theme) => {
    Vuetify.framework.theme.dark = theme === "dark";
    state.globalTheme = theme;
  },
  setRTL: (state, isRTL) => {
    Vuetify.framework.rtl = isRTL;
    state.isRTL = isRTL;
  },
  setContentBoxed: (state, isBoxed) => {
    state.isContentBoxed = isBoxed;
  },
  setMenuTheme: (state, theme) => {
    state.menuTheme = theme;
  },
  setToolbarTheme: (state, theme) => {
    state.toolbarTheme = theme;
  },
  setTimeZone: (state, zone) => {
    state.time.zone = zone;
  },
  setTimeFormat: (state, format) => {
    state.time.format = format;
  },
  setCurrency: (state, currency) => {
    state.currency = currency;
  },
  setToolbarDetached: (state, isDetached) => {
    state.isToolbarDetached = isDetached;
  },
  setUser: (state, user) => {
    state.user = user;
    state.logged = user !== undefined && user.tellae;
    state.notPermitted = user === undefined || !user.tellae;
  },
  setDashboardData: (state, data) => {
    state.users = data.users.map(u => ({
      ...u,
      customerName: u.customer? data.customers.find(c => c.uuid === u.customer)?.name : ""
    }));
    state.customers = data.customers;
    state.projects = data.projects;
    state.gtfs = data.gtfs;
  },
  setGtfsList: (state, data) => {
    state.gtfs = data
  },
  addCustomer: (state, data) => {
    state.customers = [...state.customers, data]
  },
  updateCustomer: (state, {uuid, customer}) => {
    const customerOrigin = state.customers.find(c => c.uuid === uuid);

    state.customers = [...state.customers.filter(c => c.uuid !== uuid), {...customerOrigin, ...customer}];
  },
  updateUserCustomer: (state, data) => {
    const user = state.users.filter(u => u.uuid === data.user);

    state.users = [...state.users.filter(c => c.uuid !== data.user), {...user, customer: data.customer}];
  }
};
