import menuUI from "./menus/ui.menu";
import menuApps from "./menus/apps.menu";

const info = {
  // main navigation - side menu
  menu: [
    {
      text: "",
      key: "",
      items: [
        {
          icon: "mdi-view-dashboard-outline",
          key: "menu.dashboard",
          text: "Dashboard",
          link: "/dashboard/analytics"
        }
      ]
    },
    {
      text: "Databases",
      items: [
        {
          icon: "mdi-database-search",
          key: "menu.tables",
          link: "/tables",
          text: "Tables",
          regex: /^\/tables/
        }
      ]
    },
    {
      text: "Administration",
      items: [
        {
          icon: "mdi-account-multiple-outline",
          key: "menu.users",
          link: "/users",
          text: "Users",
          regex: /^\/users/
        },
        {
          icon: "mdi-domain",
          key: "menu.customers",
          link: "/customers",
          text: "Customers",
          regex: /^\/customers/
        },
        {
          icon: "mdi-map-marker-radius",
          key: "menu.projects",
          link: "/projects",
          text: "Projects",
          regex: /^\/projects/
        },
        {
          icon: "directions_bus",
          key: "menu.gtfs",
          link: "/gtfs",
          text: "Gtfs",
          regex: /^\/gtfs/
        }
      ]
    },
  ],

  // footer links
  footer: []
};

if (window.location.host.startsWith("localhost")) {
  info.menu.push({
    text: "UI - Theme Preview",
    items: [...menuApps, ...menuUI]
  })
}

export default info;