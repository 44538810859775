var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as uuid from "uuid";
var Controller = /** @class */ (function () {
    function Controller(baseUrl, apiName) {
        if (apiName === void 0) { apiName = "api"; }
        if (baseUrl) {
            this.baseUrl = baseUrl;
        }
        else {
            if (window.location.protocol !== "https:") {
                this.baseUrl = "http://".concat(window.location.hostname, ":18080");
            }
            else {
                // Use api by default
                this.baseUrl = "https://".concat(Controller.getBaseUrl(window.location.hostname, apiName));
            }
        }
        if (this.baseUrl.endsWith("/")) {
            this.baseUrl = this.baseUrl.substr(0, this.baseUrl.length - 1);
        }
    }
    Controller.getBaseUrl = function (hostname, apiName) {
        if (apiName === void 0) { apiName = "api"; }
        return hostname.replace(/^(beta\.|pr\d+\.)?.*(\.[a-zA-Z0-9_-]+\.[a-z]+)$/, "$1".concat(apiName, "$2"));
    };
    Controller.prototype.http = function (url, options, raw) {
        var _a;
        if (options === void 0) { options = {}; }
        if (raw === void 0) { raw = false; }
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        options.headers = (_a = options.headers) !== null && _a !== void 0 ? _a : {};
                        // We should use content instead of body
                        if (typeof options.body === "object") {
                            options.body = JSON.stringify(options.body);
                            options.headers["content-type"] = "application/json";
                        }
                        else if (typeof options.content === "object") {
                            options.body = JSON.stringify(options.content);
                            options.headers["content-type"] = "application/json";
                        }
                        return [4 /*yield*/, fetch(this.getUrl(url), __assign({ credentials: "include" }, options))];
                    case 1:
                        res = _b.sent();
                        if (!raw) return [3 /*break*/, 2];
                        return [2 /*return*/, res];
                    case 2:
                        if (!(res.status === 200)) return [3 /*break*/, 4];
                        return [4 /*yield*/, res.json()];
                    case 3: return [2 /*return*/, _b.sent()];
                    case 4:
                        if (res.status >= 300) {
                            throw new Error("Server returned: ".concat(res.status));
                        }
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get current user
     * @returns
     */
    Controller.prototype.me = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http("/auth/me")];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        err_1 = _a.sent();
                        return [2 /*return*/, undefined];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Create a new customer
     * @param customerName
     * @returns
     */
    Controller.prototype.createCustomer = function (customerName) {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http("/customers", {
                                method: "POST",
                                content: {
                                    name: customerName,
                                    admins: [],
                                    features: [],
                                    uuid: uuid.v4(),
                                },
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        err_2 = _a.sent();
                        return [2 /*return*/, undefined];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Update user customer
     * @param userId
     * @param customer
     * @returns
     */
    Controller.prototype.updateUserCustomer = function (userId, customer) {
        return __awaiter(this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http("/users/".concat(userId), {
                                method: "PATCH",
                                content: { customer: customer },
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        err_3 = _a.sent();
                        return [2 /*return*/, undefined];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Update user customer
     * @param userId
     * @param customer
     * @returns
     */
    Controller.prototype.updateCustomer = function (customerId, customer) {
        return __awaiter(this, void 0, void 0, function () {
            var err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http("/customers/".concat(customerId), {
                                method: "PATCH",
                                content: customer,
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        err_4 = _a.sent();
                        return [2 /*return*/, undefined];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Controller.prototype.createApiKey = function (api_key) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http("api_keys", {
                            method: "POST",
                            content: api_key,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Controller.prototype.getApiKeys = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http("api_keys", { method: "GET" })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Controller.prototype.deleteApiKey = function (api_key) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http("api_keys/".concat(api_key.uuid), { method: "DELETE" })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Controller.prototype.getUsers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http("/admin/users")];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        err_5 = _a.sent();
                        return [2 /*return*/, []];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Controller.prototype.getGtfsList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http("graphql", {
                            method: "POST",
                            content: {
                                // query includes all objects, but Gtfs.canAct will filter unauthorized objects
                                query: "\n          query Q {\n            PublicTransports(query:\"\"){\n              results{\n                uuid\n                pt_network{\n                  uuid\n                  moa{\n                    uuid\n                    name\n                  }\n                  name\n                }\n                start_date\n                end_date\n                _creationDate\n                _lastUpdate\n                status\n                public\n              }\n            }\n          }\n        "
                            }
                        })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.data.PublicTransports.results];
                }
            });
        });
    };
    Controller.prototype.deleteGtfs = function (gtfs_uuid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.http("public_transports/".concat(gtfs_uuid), {
                        method: "DELETE"
                    })];
            });
        });
    };
    Controller.prototype.getDashboardData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var dashboard_data, gtfs_list, err_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.http("/admin/dashboard")];
                    case 1:
                        dashboard_data = _a.sent();
                        return [4 /*yield*/, this.getGtfsList()];
                    case 2:
                        gtfs_list = _a.sent();
                        return [2 /*return*/, __assign(__assign({}, dashboard_data), { gtfs: gtfs_list })];
                    case 3:
                        err_6 = _a.sent();
                        return [2 /*return*/, {
                                users: [],
                                customers: [],
                                projects: [],
                                gtfs: []
                            }];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Log the user out
     * @returns
     */
    Controller.prototype.logout = function (callback) {
        return __awaiter(this, void 0, void 0, function () {
            var promise;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promise = this.http("/auth", { method: "DELETE" });
                        return [4 /*yield*/, promise];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, callback ? callback() : ""];
                }
            });
        });
    };
    /**
     * Get an url for the controller
     *
     * If the url is absolute will just return the url back
     * @param url
     * @returns
     */
    Controller.prototype.getUrl = function (url) {
        // Add url
        if (!url.startsWith("http")) {
            url = "".concat(this.baseUrl, "/").concat(url.startsWith("/") ? url.substr(1) : url);
        }
        return url;
    };
    return Controller;
}());
export default Controller;
