export default [
    {
      path: "/gtfs",
      name: "gtfs-list",
      component: () =>
        import(/* webpackChunkName: "gtfs-list" */ "@/pages/gtfs/GtfsPage.vue")
    },
    // {
    //   path: "/users/:uuid",
    //   name: "users-edit",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "users-edit" */ "@/pages/users/EditUserPage.vue"
    //     )
    // }
  ];
  